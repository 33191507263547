import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import PaoDeMel from "../pages/PaoDeMel";
import Boticario from "../pages/Boticario";

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/paodemel" exact component={PaoDeMel} />
            <Route path="/boticario" exact component={Boticario} />
        </Switch>
    );
};

export default Routes;
