/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
import React, { useEffect } from "react";
import api from "../../services/api";

const Dois: React.FC = () => {
    useEffect(() => {
        api.get("/vendedores/loja/proximo/4/boticario").then(
            (response: {
                data: { vendedor: { celular: any }; mensagem: any };
            }) =>
                (window.location.href = `https://api.whatsapp.com/send?phone=${response.data.vendedor.celular}&text=${response.data.mensagem}`),
        );
    }, []);

    return <p />;
};

export default Dois;
